@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,200;0,400;0,600;0,700;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

$green-water: #26dbd6;
$virtus-orange: #ff9900;
$dark-blue: #288cc8;
$primary-blue: #28c0cd;

$primary: $green-water;
$link: $green-water;
$purple: #6377ff;

$family-sans-serif: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial',
	sans-serif;

$body-line-height: 1.2;

* {
	font-family: $family-sans-serif !important;
}

$title-font: 'Bitter';

@import '~@sweetalert2/theme-bulma/bulma.scss';
@import '~react-bulma-components/src/index.sass';
@import '../node_modules/bulma/bulma.sass';

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

.hidden {
	display: none;
}

.container-vp {
	max-width: 100%;
	margin: 0 auto;
	padding: 0 2rem;
}

@media (min-width: 768px) {
	.container-vp {
		max-width: 720px;
		padding: 0 1rem;
	}
}

@media (min-width: 992px) {
	.container-vp {
		max-width: 800px;
	}
}

.has-text-weight-bold {
	font-weight: 700 !important;
}

.has-text-blue {
	color: $primary-blue;
}

.has-text-blue:hover {
	color: $primary-blue;
}

.has-background-blue {
	background-color: $primary-blue;
}

.has-background-orange {
	background: $virtus-orange;
}

.bitter-font {
	font-family: $title-font, serif !important;
}

.bolder-text {
	font-weight: bold;
}

.has-orange-text {
	color: $virtus-orange;
}

.underline {
	text-decoration: underline;
}

.has-gradient-blue {
	background: linear-gradient(
		90deg,
		$dark-blue 0%,
		$green-water 100%
	) !important;
	color: white !important;
	//box-shadow: 0px 0px 12px $dark-blue;
}

.selected-indicator {
	background-color: #28c0cd;
}

.gray-background {
	background-color: #cfcfcf;
}

.virtus-bullet-content::before {
	content: '';
	margin-top: 5px;
	height: 10px;
	width: 10px;
	border-radius: 100%;
	padding: 2px;
	display: inline-block;
	opacity: 0.5;
	background: #27bdcc;
}

.virtus-bullet {
	height: 10px;
	width: 10px;
	border-radius: 100%;
	padding: 2px;
	display: inline-block;
	opacity: 0.5;
	background: #27bdcc;
}

.virtus-bullet-gray {
	height: 10px;
	width: 10px;
	border-radius: 100%;
	padding: 2px;
	display: inline-block;
	opacity: 0.5;
	background: #707070;
}

.pipedriveWebForms {
	display: flex;
	justify-content: center;
}

.is-flex-direction-column-to-row {
	flex-direction: column !important;

	@media (min-width: 768px) {
		flex-direction: row !important;
	}
}

.testimonial-quote {
	border-radius: 40px;
	background-color: #e1ffc7;
	position: relative;
	z-index: 10;
	min-height: 120px;
}

.testimonial-quote p {
	position: relative;
	z-index: 2 !important;
}

.check-wpp {
	position: absolute;
	right: 10px;
	bottom: 5px;
	color: rgb(0, 238, 255);
}

.testimonial-quote-drop {
	position: absolute;
	z-index: 1;
	left: 17px;
	background-color: #e1ffc7;
	transform: rotate(220deg);
	content: '';
	top: -1px;
	height: 40px;
	width: 40px;
}

.accordion {
	cursor: pointer;
}

.panel {
	padding: 15px 18px;
	margin-top: 15px;
	overflow: hidden;
}

.accordion .panel {
	margin-bottom: 5px;
	border-radius: 0;
	border-bottom: 1px solid #efefef;
}

.accordion .panel-heading {
	background: #f2f5f7;
	padding: 13px;
	width: 100%;
	display: block;
}

.accordion .panel:hover {
	background: #f2f5f7;
}

.panel {
	border-radius: 6px;
	box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
		0 0 0 1px rgba(10, 10, 10, 0.02);
	font-size: 1rem;
}

.panel:not(:last-child) {
	margin-bottom: 1.5rem;
}

.blue-gradient {
	background: linear-gradient(90deg, $dark-blue 0%, $primary-blue 100%);
	//box-shadow: 0px 0px 12px $dark-blue;
}

.benefits-card {
	background-color: #000000;
	border-radius: 25px;
	padding: 3rem 1.5rem;
}

.width-80 {
	width: 80%;
}

.selected_indicator {
	background: #d4f3f5;
	border: 1px solid #28c0cd;

	p {
		color: #28c0cd;
	}
}

/**
* Input
*/
.select-search__input {
	display: block;
	height: 36px;
	width: 100%;
	padding: 0 40px 0 16px;
	background: #fff;
	border: 1px solid transparent;
	box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	outline: none;
	font-family: 'Noto Sans', sans-serif;
	font-size: 14px;
	text-align: left;
	text-overflow: ellipsis;
	line-height: 36px;
	-webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
	-webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
	cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
	background: #fff;
	box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
	list-style: none;
	padding: 0;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
	border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__input {
	border: 1px solid rgba(10, 10, 10, 0.1);
	box-shadow: none;
	border-radius: 4px;
	font-size: 0.85rem;
	width: 100%;
	min-height: 46px;
	background-color: white;
}

.select-search__option,
.select-search__not-found {
	height: 36px;
	width: 100%;
	padding: 0 16px;
	background: #fff;
	border: none;
	outline: none;
	font-family: 'Noto Sans', sans-serif;
	font-size: 14px;
	font-weight: normal;
	text-align: left;
	cursor: pointer;
}
