.group {
	position: relative;
	margin-bottom: 10px;
}

input,
select {
	border: 1px solid rgba(10, 10, 10, 0.1);
	border-radius: 4px;
	padding: 14px 20px;
	font-size: 0.85rem;
	width: 100%;
}

.input-ld {
	border: 1px solid rgba(10, 10, 10, 0.1);
	border-radius: 4px;
	padding: 14px 39px 14px 20px;
	font-size: 0.85rem;
	width: 100%;
	word-wrap: break-word;
	height: 65px;
	line-break: normal;
}

input:focus,
select:focus {
	outline: none;
}
