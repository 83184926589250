@import '../../../../main.scss';

.installment-active {
	background-color: $primary-blue !important;
	color: white !important;
}

.offer-active {
	border: 2px solid #27bdcc !important;
}
