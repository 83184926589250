@import 'animate.css';

#backdrop {
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 98;
	background: rgba(10, 10, 10, 0.4);
}

#drawer {
	position: fixed;
	top: 0;
	right: 0;
	width: 70vw;
	height: 100vh;
	background-color: #ffffff;
	z-index: 99;
	transition: all 2s ease-out;
}

@media (min-width: 2000px) {
	#navLinks {
		justify-content: flex-start !important;
	}

	.navbar > .container {
		justify-content: center !important;
	}
}
